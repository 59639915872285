@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';

.chat {
  $breakpoint: 900px;
  display: flex;
  flex-direction: column;
  gap: spacing.$l;
  max-width: $breakpoint;

  &__header {
    display: flex;
    gap: spacing.$m;
    align-items: center;
    margin-bottom: spacing.$s;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }

  &__section-inner-opening-hours,
  &__section-inner {
    background: light.$surface-primary-default;
    border-radius: corner-radius.$s;
    overflow: hidden;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
    color: light.$on-surface-primary-alternate;
    padding: spacing.$m;
  }

  &__section-inner-opening-hours {
    padding: spacing.$m;
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }

  &__opening-hours-group {
    display: grid;
    grid-template-columns: repeat(2, 250px);
  }

  &__opening-hours-item {
    display: flex;
    gap: spacing.$xxs;
    align-items: center;
  }

  &__section-list {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    margin: 0;
    padding: spacing.$m spacing.$l;
    margin-left: spacing.$m;
  }

  &__trigger {
    display: flex;
    gap: spacing.$xs;
    position: fixed;
    bottom: 0;
    background-color: light.$surface-primary-default;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
    text-align: center;

    @media (min-width: $breakpoint) {
      left: 250px; // NOTE: Left spacing is due to menu width on desktop.
      padding-left: spacing.$xxl;
      text-align: left;
    }
  }
}
